<template>
  <b-modal
    id="plan_options"
    hide-header
    hide-footer
    body-class="plan-options__container"
    centered
    size="lg"
  >
    <div class="modal-header">
      <Close class="icon" @click="closeModal" />
    </div>

    <p class="title__xxl">Conheça nossos planos</p>

    <p class="subtitle">
      Selecione um de nossos planos, em breve entramos em contato para ativação
      do plano que escolheu para sua clínica.
    </p>

    <div class="plan-cards">
      <b-card
        v-for="plan in plans"
        :key="plan.title"
        class="plan-card"
        :class="{ 'recommended-card': plan.recommended }"
      >
        <span class="recommended" v-if="plan.recommended">
          mais recomendado
        </span>

        <p class="title__xxl">{{ plan.title }}</p>

        <div class="price__container">
          <p>de R$ {{ plan.value.toFixed(2) }} por</p>
          <p class="title__xxl">R$ {{ plan.promotional_value.toFixed(2) }}</p>
        </div>

        <ul>
          <li>
            <span class="checkmark"></span>
            {{ plan.amount_invoices }} emissões de notas
          </li>
          <li>
            <span class="checkmark"></span>
            R$ {{ plan.unit_value_invoices.toFixed(2) }} por nota excedente
          </li>
          <li>
            <span class="checkmark"></span>
            {{ plan.users_quantity }} usuários aptos a emitir nota
          </li>
          <li>
            <span class="checkmark"></span>
            PDF, XML para download
          </li>
        </ul>

        <div class="text-center">
          <b-button
            class="card__button"
            @click="selectPlan(plan)"
            :class="{ recommended__button: plan.recommended }"
          >
            Selecionar plano
            <Arrow />
          </b-button>
        </div>
      </b-card>
    </div>

    <div class="my-4 text-center">
      <b-button variant="outline-primary" @click="closeModal">
        Voltar
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import api from '@/modules/newFeatures/api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    planSelectedData: Array
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Arrow: () => import('@/assets/icons/arrow-right.svg')
  },
  data() {
    return {
      plans: [
        {
          id: 1,
          title: 'Plano A',
          recommended: 0,
          description: 'Plano A descrição bla bla bla.',
          value: 100.2,
          promotional_value: 89.2,
          amount_invoices: 10,
          users_quantity: 5,
          unit_value_invoices: 2.0
        },
        {
          id: 2,
          title: 'Plano B',
          recommended: 1,
          description: 'Plano B descrição bla bla bla.',
          value: 198,
          promotional_value: 155,
          amount_invoices: 100,
          users_quantity: 10,
          unit_value_invoices: 3.0
        },
        {
          id: 3,
          title: 'Plano C',
          recommended: 0,
          description: 'Plano A descrição bla bla bla.',
          value: 220,
          promotional_value: 198,
          amount_invoices: 500,
          users_quantity: 20,
          unit_value_invoices: 4.5
        }
      ]
    }
  },
  methods: {
    async selectPlan(plan) {
      const isLoading = this.$loading.show()
      try {
        await api.selectPlan({
          amount: plan.amount_invoices,
          clinic_id: getCurrentClinic().id,
          company_limit: plan.users_quantity,
          unity_value: plan.unit_value_invoices,
          month_value: plan.promotional_value
        })
        this.closeModal()
        this.$toast.success(
          'Recebemos sua solicitação e em breve retornaremos!'
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeModal() {
      this.$bvModal.hide('plan_options')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';

#plan_options {
  .plan-options__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-header {
      width: 100%;
      border-bottom: none;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      padding: 24px !important;
    }

    .title__xxl {
      margin-top: 0;
    }

    .subtitle {
      max-width: 500px;
      font-size: 14px;
      font-family: 'Nunito Sans';
      font-weight: 500;
      text-align: center;
      color: var(--neutral-700);
    }

    .icon {
      width: 25px;
      fill: var(--neutral-500);
    }

    .plan-cards {
      display: flex;

      .plan-card {
        border: none;
        width: 260px;
        height: 380px;
        border-radius: $border-radius-base;
        box-shadow: 0px 24px 48px -8px #0c1d5933;

        &.recommended-card {
          z-index: 2;
          width: 276px;
          height: 422px;
          box-shadow: 0px 24px 48px -8px #0c1d5933;
        }

        &:first-child {
          position: relative;
          left: 20px;
          top: 20px;
        }

        &:last-child {
          position: relative;
          right: 20px;
          top: 20px;
        }

        .recommended {
          padding: 0 8px;
          font-size: 12px;
          font-weight: 600;
          border-radius: 22px;
          color: var(--neutral-70);
          font-family: 'Nunito Sans';
          background: var(--blue-600);
        }

        .card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          > .title__xxl {
            color: var(--blue-600);
          }
        }

        .price__container {
          color: var(--dark-blue);
          p:first-child {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
          }
        }

        ul {
          padding: 0;
          font-size: 14px;
          font-weight: 500;
          list-style: none;
          color: var(--neutral-700);
          list-style-type: none;
        }

        .checkmark {
          box-sizing: border-box;
          position: relative;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          color: var(--states-success);
          margin-right: 1.2rem;
          border: 2px solid transparent;
          border-radius: 100px;
        }

        .checkmark::after {
          content: '';
          box-sizing: border-box;
          position: absolute;
          left: 3px;
          top: -2px;
          width: 5px;
          height: 13px;
          border-width: 0 2px 2px 0;
          border-style: solid;
          transform-origin: bottom left;
          transform: rotate(45deg);
        }

        .card__button {
          border: none;
          font-weight: 700;
          border-radius: $border-radius-base;
          background: var(--neutral-700);
          &.recommended__button {
            background: var(--orange);
          }
        }
      }
    }
  }
}
</style>
